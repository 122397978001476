import { IconType } from 'components/Icons'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormField, FormFieldType } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useHeaderState'
import { components } from 'types/api-interface'
import { Writeable } from '../../../shared/helper/types/writable'
import PayoutDocumentsModal from '../ChapterOne/PayoutDocuments/PayoutDocumentsModal'
import PayoutmodelDocumentsSummary from '../ChapterOne/PayoutDocuments/PayoutDocumentsSummary'

const FormStepHealtInsurance: React.FC<{
	data?: components['schemas']['AuszahlungsoptionenArbeitskopie'] | undefined | void | null
	onSubmit: (obj: components['schemas']['AuszahlungsoptionenArbeitskopie'] | null) => void
	editable?: boolean
}> = (props) => {
	const { t } = useTranslation()
	const [insuranceType, setInsuranceType] = useState<string | undefined>(props?.data?.krankenkasse?.type)
	const [, modalActions] = useGlobalModalState()
	const { getChildPath } = useRouteHelper()
	useBackButtonPath(`${getChildPath(routesDictionary.payoutOptions, 'payoutModelInputForm')}/4`)

	const isRequired = props.data?.tax?.staendigerWohnsitzInDeutschland === true
	const [disabled, setIsDisabled] = useState(false)
	const dynamicLabel = t(
		`view.PayoutInputForm.healthInsurance.${insuranceType === 'privat' ? 'namePrivate' : 'namePublic'}`
	)

	const onHealthInsuranceTypeChange = (e: any) => {
		if (e.target.value === 'none' && (isRequired || props.data?.krankenkasse?.type)) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}

	const fields: { [key: string]: FormField } = {
		healthInsuranceType: {
			fieldType: FormFieldType.select,
			label: t('view.PayoutInputForm.healthInsurance.typeLabel'),
			value: props.data?.krankenkasse?.type,
			required: isRequired,
			alternativeStyle: true,
			className: 'select-with-background',
			onChange: onHealthInsuranceTypeChange,
			options: [
				{
					label: t('view.PayoutInputForm.healthInsurance.optionPleaseChoose'),
					value: 'none',
				},
				{
					label: t('view.PayoutInputForm.healthInsurance.optionPublic'),
					value: 'gesetzlich',
				},
				{
					label: t('view.PayoutInputForm.healthInsurance.optionPrivate'),
					value: 'privat',
				},
			],
			disabled: !props.editable,
		},
		healthInsuranceName: {
			fieldType: FormFieldType.text,
			label: dynamicLabel,
			required: isRequired,
			value: props.data?.krankenkasse?.name || '',
			readOnly: !props.editable,
		},
	}

	if (props.data && 'pflegeversicherung' in props.data) {
		fields.elterneigenschaft = {
			checked: props.data?.pflegeversicherung?.elterneigenschaft,
			fieldType: FormFieldType.checkBox,
			label: t('view.PayoutInputForm.socialInsurance.checkbox'),
			value: 1,
		}
	}
	return (
		<>
			<h3>{t('view.PayoutInputForm.healthInsurance.headline')}</h3>
			{props.data && (
				<Form
					disabled={disabled}
					onFormChange={(fields) => {
						setInsuranceType(fields.healthInsuranceType.value as unknown as string)
					}}
					submitLabel={`${t('generic.next')}
				
			`}
					fields={fields}
					onSubmit={(e: any) => {
						if (!props.editable) {
							props.onSubmit(null)
							return { successful: true, status: 200 }
						}
						let payload: Partial<Writeable<components['schemas']['AuszahlungsoptionenArbeitskopie']>> = {
							krankenkasse: {
								type: e.healthInsuranceType,
								name: e.healthInsuranceName,
							},
						}

						if (!isRequired && e.healthInsuranceType === '' && e.healthInsuranceName === 'none') {
							payload.krankenkasse = null
						}

						if (props.data && 'pflegeversicherung' in props.data) {
							payload.pflegeversicherung = { elterneigenschaft: e.elterneigenschaft ? true : false }
						}

						props.onSubmit(payload)

						return { successful: true, status: 200 }
					}}
					children={
						<>
							{props.data && 'pflegeversicherung' in props.data && (
								<div className="subheadline subheadline--pflegeversicherung">
									{t('view.PayoutInputForm.socialInsurance.pflegeversicherungHeadline')}
								</div>
							)}

							<div className="subheadline subheadline--health-insurance ">
								{t('view.PayoutInputForm.healthInsurance.subheadline')}
							</div>

							{insuranceType === 'privat' && (
								<div className="info-content info-content--health-insurance">
									<p>
										{' '}
										<Trans i18nKey="view.PayoutInputForm.documentHeadline"></Trans>
									</p>

									<PayoutmodelDocumentsSummary
										type={'KRANKENVERSICHERUNG'}
										noFileContent={
											<Button
												type={ButtonType.secondary}
												label={t('view.PayoutInputForm.uploadDocumentButton')}
												onClick={() => {
													modalActions.setContent(
														<PayoutDocumentsModal
															type={'KRANKENVERSICHERUNG'}
															editable={props.editable}
														></PayoutDocumentsModal>
													)
													modalActions.openModal()
												}}
												disabled={!props.editable}
											></Button>
										}
									></PayoutmodelDocumentsSummary>

									<div className="info-box">
										<Icon type={IconType.alert} color={'var(--color-cyan)'}></Icon>
										<p className="text-color-blue margin-top margin">
											{t('view.PayoutInputForm.documentBody')}
										</p>
									</div>
								</div>
							)}
						</>
					}
				></Form>
			)}
		</>
	)
}

export default FormStepHealtInsurance
