import useApi, { QueryKey } from 'hooks/useApi'
import { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'
import { components } from 'types/api-interface'
import { ProgressKeys } from '../PayoutOverview'
import PayoutDocumentsModal from './PayoutDocumentsModal'
import PayoutmodelDocumentsSummary from './PayoutDocumentsSummary'

export const checkPensionConfirmationRequired = (data: any) => {
	const type = data?.leistungsart?.type || false

	if (!type) {
		return false
	}

	return type === 'VorgezogenerRuhestand' || type === 'Erwerbsminderung'
}

export const checkInsuranceConfirmationRequired = (data: any) => {
	const type = data?.krankenkasse?.type || false

	if (!type) {
		return false
	}

	return type === 'privat'
}

const PayoutDocuments = () => {
	const { navigateTo, getChildPath } = useRouteHelper()
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'payoutOverview'))
	useNavigationTitle(<Trans i18nKey="view.payoutOptions.chapterThree.navigationName"></Trans>)
	const queryClient = useQueryClient()
	const [, modalActions] = useGlobalModalState()
	const api = useApi()
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)

	const [chapterComplete, setChapterComplete] = useState<boolean>(false)

	const filesQuery = useQuery(QueryKey.payoutDocuments, api.getPayoutModelDocuments)

	const { data } = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)
	const nextView = () => navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutDocumentsSuccess'))

	const mutation = useMutation(api.patchPayoutModel, {
		onSuccess: (data) => {
			queryClient.setQueryData(QueryKey.payoutModelInProgress, data)
			nextView()
		},
	})

	const onClick = (type: components['schemas']['DocumentType']) => {
		modalActions.setContent(<PayoutDocumentsModal type={type} editable={isEditable?.editable}></PayoutDocumentsModal>)
		modalActions.openModal()
	}

	const onNextViewClick = async () => {
		if (!isEditable?.editable) {
			nextView()
			return
		}
		await mutation.mutate({ progressKey: ProgressKeys.CHAPTER_THREE_COMPLETED })
	}

	const containsFileType = (type: components['schemas']['DocumentType']): boolean => {
		if (filesQuery?.data?.length === 0 || !type) {
			return false
		}

		const result = filesQuery.data?.filter((v) => {
			return v.fileName?.includes(type) || false
		})

		return (result && result.length > 0) || false
	}

	const pensionConfirmationRequired = useMemo(() => {
		return checkPensionConfirmationRequired(data)
	}, [data])

	const insuranceConfirmationRequired = useMemo(() => {
		return checkInsuranceConfirmationRequired(data)
	}, [data])

	useEffect(() => {
		if (pensionConfirmationRequired && !containsFileType('RENTENBESCHEINIGUNG')) {
			setChapterComplete(false)
			return
		}

		if (insuranceConfirmationRequired && !containsFileType('KRANKENVERSICHERUNG')) {
			setChapterComplete(false)
			return
		}

		if (!containsFileType('BANKVERBINDUNG')) {
			setChapterComplete(false)
			return
		}

		setChapterComplete(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, filesQuery.data, pensionConfirmationRequired, insuranceConfirmationRequired])

	return (
		<div className="grid center-view payout-documents">
			<div className="grid__top">
				<h3 className="bold b">
					<b>
						<Trans i18nKey="view.payoutOptions.chapterThree.headline"></Trans>
					</b>
				</h3>
				<p>
					<Trans i18nKey="view.payoutOptions.chapterThree.bodyText"></Trans>
				</p>
			</div>

			<div className="grid__center">
				{pensionConfirmationRequired && (
					<div className="payout-documents__status">
						<h3 className="headline text-color-cyan ">
							<Trans i18nKey="view.payoutOptions.chapterThree.documents.pension.documentName"></Trans>
						</h3>

						<PayoutmodelDocumentsSummary
							type={'RENTENBESCHEINIGUNG'}
							noFileContent={
								<>
									{' '}
									<div className="is-missing caption  margin--vertical margin--small">
										{' '}
										<Trans i18nKey="view.payoutOptions.chapterThree.documents.pension.missing"></Trans>
									</div>
									<p>
										<Trans i18nKey="view.payoutOptions.chapterThree.documents.pension.bodyText"></Trans>
									</p>
									<Button
										onClick={() => {
											onClick('RENTENBESCHEINIGUNG')
										}}
										type={ButtonType.primary}
										disabled={!isEditable?.editable}
									>
										<Trans i18nKey="view.payoutOptions.chapterThree.button"></Trans>
									</Button>
								</>
							}
						/>
					</div>
				)}

				{insuranceConfirmationRequired && (
					<div className="payout-documents__status">
						<h3 className="headline text-color-cyan ">
							<Trans i18nKey="view.payoutOptions.chapterThree.documents.insurance.documentName"></Trans>
						</h3>

						<PayoutmodelDocumentsSummary
							type={'KRANKENVERSICHERUNG'}
							noFileContent={
								<>
									<div className="is-missing caption  margin--vertical margin--small">
										{' '}
										<Trans i18nKey="view.payoutOptions.chapterThree.documents.insurance.missing"></Trans>
									</div>
									<p>
										<Trans i18nKey="view.payoutOptions.chapterThree.documents.insurance.bodyText"></Trans>
									</p>

									<Button
										type={ButtonType.primary}
										onClick={() => {
											onClick('KRANKENVERSICHERUNG')
										}}
										disabled={!isEditable?.editable}
									>
										{' '}
										<Trans i18nKey="view.payoutOptions.chapterThree.button"></Trans>
									</Button>
								</>
							}
						></PayoutmodelDocumentsSummary>
					</div>
				)}
				<div className="payout-documents__status">
					<h3 className="headline text-color-cyan ">
						<Trans i18nKey="view.payoutOptions.chapterThree.documents.misc.documentName"></Trans>
					</h3>

					<PayoutmodelDocumentsSummary
						type={'BANKVERBINDUNG'}
						noFileContent={
							<>
								{' '}
								<div className="is-missing caption  margin--vertical margin--small">
									{' '}
									<Trans i18nKey="view.payoutOptions.chapterThree.documents.bankAccount.missing"></Trans>
								</div>
								<p>
									<Trans i18nKey="view.payoutOptions.chapterThree.documents.misc.bodyText"></Trans>
								</p>
								<Button
									type={ButtonType.primary}
									onClick={() => {
										onClick('BANKVERBINDUNG')
									}}
									disabled={!isEditable?.editable}
								>
									{' '}
									<Trans i18nKey="view.payoutOptions.chapterThree.button"></Trans>
								</Button>
							</>
						}
					></PayoutmodelDocumentsSummary>
				</div>
				<div className="">
					<Button type={ButtonType.primary} disabled={!chapterComplete} onClick={onNextViewClick}>
						<Trans i18nKey="view.payoutOptions.chapterThree.completeChapter"></Trans>
					</Button>
				</div>
			</div>
		</div>
	)
}

export default PayoutDocuments
