import useApi, { QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { components } from 'types/api-interface'
import { PayoutDocumentEntry } from './PayoutDocumentsEntry'
import PayoutDocumentsModal from './PayoutDocumentsModal'

type TS3File = {
	sortKey: string
	type: string
	fileId: string
	s3Path: string
	fileCreatedAt: string
	fileName: string
	page?: number | undefined
	numberOfPages?: number | undefined
	fileConfirmedAt?: string | undefined
	uploadStatus?: string
	merged?: boolean
	mimeType?: string
}

const POLLING_INTERVAL_TIME = 8000

const PayoutmodelDocumentsSummary: FC<{
	type: components['schemas']['DocumentType']
	noFileContent: JSX.Element
	showMinimalView?: boolean
	onFilesLoaded?: (filterdFiles: TS3File[], type: components['schemas']['DocumentType']) => void
	preventUpload?: components['schemas']['DocumentType'][] | undefined
}> = (props) => {
	const [files, setFiles] = useState<TS3File[] | null>(null)
	const [, modalActions] = useGlobalModalState()

	const api = useApi()

	const { data, status, refetch } = useQuery(QueryKey.payoutDocuments, api.getPayoutModelDocuments)
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)
	const payoutModelInProgressQuery = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)

	const onDeleteClick = async (key: string) => {
		await api.deleteFile(key)
		refetch()
	}

	useEffect(() => {
		if (!data) {
			return
		}

		const filteredFiles = data.filter((v) => {
			// FIXME: `merge` does not exist in schema. Is it only missing?!
			return v.fileName.includes(props.type) && !(v as any).merged
		})

		if (filteredFiles.length === 0) {
			setFiles(null)
			return
		}

		setFiles(filteredFiles)
		props?.onFilesLoaded && props.onFilesLoaded(filteredFiles, props.type)
	}, [data, props])

	const hasUnfinishedFiles = (files: TS3File[]) => {
		return (
			files.filter((v) => {
				if (v.uploadStatus !== 'finished') {
					return true
				} else {
					return false
				}
			}).length > 0
		)
	}

	useEffect(() => {
		const pollFiles = () => {
			if (status === 'success') {
				setTimeout(() => {
					refetch()
				}, POLLING_INTERVAL_TIME)
			}
		}

		if (!files || files.length === 0) {
			return
		}

		if (hasUnfinishedFiles(files)) {
			pollFiles()
			return
		}
	}, [files, refetch, status])

	if (status !== 'success') {
		return <></>
	}

	if (!files) {
		return <>{props.noFileContent}</>
	}

	const lastUploadedFileDate = files[files.length - 1].fileCreatedAt

	if (props.showMinimalView) {
		return (
			<div className="file-upload-summary file-upload-summary--reduced">
				<p className="margin--bottom">
					<span className="text-color-cyan">{props.type}</span>
					<br />
					{t('view.payoutOptions.chapterThree.document', { count: files.length })}
					<br /> {t('generic.lastUpload')}:{' '}
					{lastUploadedFileDate && new Date(lastUploadedFileDate).toLocaleString()}
				</p>
			</div>
		)
	}

	return (
		<>
			{payoutModelInProgressQuery.data && data && (
				<div className="file-upload-summary">
					{files.map((v, i) => {
						return (
							<div key={i}>
								<PayoutDocumentEntry
									state="DISPLAY"
									name={v.fileName}
									s3Path={v.s3Path}
									fileId={v.fileId}
									file={undefined}
									type={props.type}
									page={i + 1}
									uploadedDate={v.fileCreatedAt}
									onDeleteClick={() => {
										onDeleteClick(v.sortKey)
									}}
									numberOfPages={v.numberOfPages ? v.numberOfPages : undefined}
									onFileSelect={() => { }}
									loadExternalThumbnail={v.uploadStatus === 'finished'}
									editable={isEditable?.editable}
								></PayoutDocumentEntry>
							</div>
						)
					})}
					{isEditable?.editable && (
						<Button
							onClick={() => {
								modalActions.setContent(<PayoutDocumentsModal type={props.type} editable={isEditable.editable}></PayoutDocumentsModal>)
								modalActions.openModal()
							}}
							type={ButtonType.small}

						>
							{t('view.payoutOptions.chapterThree.addDocument')}
						</Button>
					)}
				</div>
			)}
		</>
	)
}
export default PayoutmodelDocumentsSummary
