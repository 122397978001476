import PayoutModelList from 'components/PayoutModelList'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserGroup } from 'hooks/useUserGroup'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useNavigationTitle } from 'state/useHeaderState'

export enum benefitTypes {
	retirement = 'Ruhestand',
	earlyRetirement = 'VorgezogenerRuhestand',
	reducedEarning = 'Erwerbsminderung',
}
const BenefitType = () => {
	const api = useApi()
	const { navigateTo, getChildPath } = useRouteHelper()
	const [value, setValue] = useState<number | string>()
	useNavigationTitle(<Trans i18nKey="view.payoutOptions.chapterOne.benefitType.pageTitle" />)
	useBackButtonPath(getChildPath(routesDictionary.payoutOptions, 'payoutOverview'))
	const { isVO20, isHigherUp } = useUserGroup()
	const transKey = isHigherUp ? 'EMGGET' : isVO20 ? 'Vo20' : ''

	type TLeistungsArt = Awaited<ReturnType<typeof api.getPayoutTypes>>['leistungsartTypes'][number]

	const { status, data } = useQuery(QueryKey.payoutTypes, api.getPayoutTypes)
	const payoutModelQuery = useQuery(QueryKey.payoutModelInProgress, api.getPayoutModelInProgress)
	const { data: isEditable } = useQuery(QueryKey.payoutModelEditable, api.isPayoutModelEditable)
	const { t } = useTranslation()

	const isDisabled = (key: TLeistungsArt): boolean => {
		return data !== undefined && data.leistungsartTypes !== undefined && !data.leistungsartTypes.includes(key)
	}

	const savedBenefitType = localStorage.getItem('benefit-type')
	const parsedSavedBenefitType = savedBenefitType ? JSON.parse(savedBenefitType) : null

	const currentBenefitType =
		parsedSavedBenefitType || (payoutModelQuery.data?.leistungsart?.type as benefitTypes) || false
	const disableIfNotEditableAndNotCurrentBenefitType = (benefitTypes: benefitTypes) => {
		return !isEditable?.editable && currentBenefitType !== benefitTypes
	}

	const items: {
		value: benefitTypes
		description: string
		subLabel: string | JSX.Element
		label: string
		disabled: boolean
		checked?: boolean
	}[] = [
			{
				value: benefitTypes.retirement,
				label: t('view.payoutOptions.chapterOne.benefitType.options.0.headline'),
				subLabel: (
					<span>
						<span className="hidden-hint">
							{t('view.payoutOptions.chapterOne.benefitType.options.0.subLabel' + transKey)}
						</span>
						{isDisabled('Ruhestand') && (
							<>
								<br />
								{data?.ruhestandAvailabilityYear && (
									<span className="visible-hint">
										<Trans
											i18nKey="view.payoutOptions.chapterOne.benefitType.options.0.activeByDate"
											values={{ ruhestandAvailabilityYear: data?.ruhestandAvailabilityYear }}
										></Trans>
									</span>
								)}
							</>
						)}
					</span>
				),
				description: t('view.payoutOptions.chapterOne.benefitType.options.0.description'),
				disabled: isDisabled('Ruhestand') || disableIfNotEditableAndNotCurrentBenefitType(benefitTypes.retirement),
				checked: currentBenefitType === benefitTypes.retirement && !isDisabled('Ruhestand'),
			},
			{
				value: benefitTypes.earlyRetirement,
				label: t('view.payoutOptions.chapterOne.benefitType.options.1.headline'),
				subLabel: <>{t('view.payoutOptions.chapterOne.benefitType.options.1.subLabel' + transKey)}</>,
				description: t('view.payoutOptions.chapterOne.benefitType.options.1.description'),
				disabled:
					isDisabled('VorgezogenerRuhestand') ||
					disableIfNotEditableAndNotCurrentBenefitType(benefitTypes.earlyRetirement),
				checked: currentBenefitType === benefitTypes.earlyRetirement && !isDisabled('VorgezogenerRuhestand'),
			},
			{
				value: benefitTypes.reducedEarning,
				label: t('view.payoutOptions.chapterOne.benefitType.options.2.headline'),
				subLabel: <>{t('view.payoutOptions.chapterOne.benefitType.options.2.subLabel' + transKey)} </>,
				description: t('view.payoutOptions.chapterOne.benefitType.options.2.description'),
				disabled:
					isDisabled('Erwerbsminderung') ||
					disableIfNotEditableAndNotCurrentBenefitType(benefitTypes.reducedEarning),
				checked: currentBenefitType === benefitTypes.reducedEarning && !isDisabled('Erwerbsminderung'),
			},
		]

	const nextViewClick = () => {
		if (!value) {
			return
		}

		localStorage.setItem('benefit-type', JSON.stringify(value))

		switch (value) {
			case benefitTypes.retirement:
				navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'pensionDate')}/${value}`)
				break
			case benefitTypes.earlyRetirement:
				navigateTo(`${getChildPath(routesDictionary.payoutOptions, 'pensionDate')}/${value}`)
				// prefetchRetirementData()
				break
			case benefitTypes.reducedEarning:
				navigateTo(getChildPath(routesDictionary.payoutOptions, 'payoutReduction'))
				break
		}
	}

	return (
		<div className="center-view payout-benefit-type">
			<h3>
				<span className="font-family-alternative--light">
					<Trans i18nKey="view.payoutOptions.chapterOne.benefitType.introText1" />
					<br />
				</span>
				<Trans i18nKey="view.payoutOptions.chapterOne.benefitType.introText2" />
			</h3>
			{status === 'success' && (
				<>
					<PayoutModelList
						className="grid__center"
						items={items}
						onChange={(val: number) => {
							if (typeof val === 'number' && !isNaN(val)) {
								const updatedValue = items[val].value
								setValue(updatedValue)
								return
							}
							setValue(val)
						}}
					/>
					<div className="padding padding--top">
						<Button
							disabled={!!(value && isDisabled(value as TLeistungsArt))}
							type={ButtonType.primary}
							label={t('view.payoutOptions.chapterOne.benefitType.submit')}
							onClick={nextViewClick}
						></Button>
					</div>
				</>
			)}
		</div>
	)
}

export default BenefitType
