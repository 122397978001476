import { useAuth } from 'hooks/useAuth'
import { useQueryParams } from 'raviger'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields, FormRefActions } from 'shared/components/Form'
import { ModalRefActions } from 'shared/components/Modal'
import { TextInputType } from 'shared/components/TextInput'
import Tooltip from 'shared/components/Tooltip'
import isDev from 'shared/helper/isDev'
import { parseQueryString } from 'shared/helper/parseQueryString'
import useGlobalFormState from 'shared/hooks/useGlobalFormState'
import { useBackButtonClickHandler } from 'state/useHeaderState'
import SendPasswordRegisterBodytext from '../RegisterPartials/SendPasswordRegisterBodytext'

/** USED FOR V2 */
const SendPasswordV2: React.FunctionComponent<{ loginOnSubmit?: boolean }> = () => {
	const [queryParams] = useQueryParams(parseQueryString)

	const { t } = useTranslation()
	const auth = useAuth()
	const challenge = auth.challengeV2

	useBackButtonClickHandler({ name: auth.resetChallenge })

	const [errorMessages, setErrorMessages] = useState<string[]>()
	const [formState, formActions] = useGlobalFormState()
	const sendPasswordForm = useRef<FormRefActions>()

	const modal = useRef<ModalRefActions>()
	const inputElements: FormFields = useMemo(() => {
		let fields: FormFields = {
			password: {
				value:
					formState.password ||
					(isDev()
						? queryParams.setInitialPassword
							? (process.env.REACT_APP_INITIAL_PASSWORD as string)
							: (process.env.REACT_APP_PASSWORD as string)
						: ''),
				required: true,
				type: TextInputType.password,
				label: queryParams.setInitialPassword
					? t('view.register.formFieldLabels.initialPassword')
					: t('generic.password'),
				hidePasswordLabel: queryParams.setInitialPassword
					? t('view.register.formFieldLabels.hideInitialPassword')
					: undefined,
				showPasswordLabel: queryParams.setInitialPassword
					? t('view.register.formFieldLabels.showInitialPassword')
					: undefined,
				autoComplete: queryParams.setInitialPassword ? 'none' : 'current-password',
				fieldType: FormFieldType.text,
				append: queryParams.setInitialPassword ? (
					<Tooltip
						label={t('view.register.sendPassword.help.headline')}
						header={t('view.register.sendPassword.help.header')}
						headline={t('view.register.sendPassword.help.headline')}
						content={<Trans i18nKey="view.register.sendPassword.help.content" />}
					/>
				) : undefined,
			},
		}

		if (!queryParams.register && queryParams.setInitialPassword) {
			fields['termsandconditions'] = {
				required: true,
				fieldType: FormFieldType.checkBox,
				label: t('view.login.formFieldLabels.readTermsAndConditions'),
				size: 'large',
				className: 'font-size-s margin--vertical',
				prepend: (
					<Button
						type={[ButtonType.link]}
						label={t('view.register.buttonLabel.goToTermsAndConditions')}
						onClick={() => modal.current?.openModal()}
						className="font-size-s text-color-cyan margin--vertical"
					/>
				),
			}
		}

		return fields
		// eslint-disable-next-line
	}, [queryParams.setInitialPassword, queryParams.register, t])

	useEffect(() => {
		if (undefined === challenge.lastChallengeError) {
			return
		}

		switch (challenge.lastChallengeError) {
			// in the past, auth v1 were used for unknown users
			// this is the resulting error when requesting to login an unknown user or wrong password 
			case 'UserLambdaValidationException':
				setErrorMessages([
					t('apiErrors.RegisterNotAuthorizedException'),
				])
				break
			default:
				setErrorMessages([
					t(`apiErrors.${challenge.lastChallengeError}`, { error: challenge.lastChallengeError }),
					// t('view.login.formErrors.remainingTries', {
					// 	remainingTries: challenge.remainingTries,
					// }),
				])

				sendPasswordForm.current?.handleFormError()

				return
		}
		// eslint-disable-next-line
	}, [challenge])

	if (queryParams.initialPassword) {
		return null
	}

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans
						i18nKey={`view.${queryParams.setInitialPassword ? 'register' : 'login'}.sendPassword.pageTitle`}
					/>
				</h1>
			</div>

			<div className="form-view__bodytext">
				{queryParams.setInitialPassword && <SendPasswordRegisterBodytext />}
			</div>

			<Form
				ref={sendPasswordForm}
				className="form-view"
				fields={inputElements}
				onSubmit={(submittedFields: { [key: string]: any }) => {
					formActions.setFields(submittedFields)
					return auth.authenticateMigratedUser(submittedFields)
				}}
				errorMessages={errorMessages}
				submitLabel={t(
					`view.${queryParams.setInitialPassword ? 'register' : 'login'}.buttonLabel.sendPassword`
				)}
				promiseTracker={{ area: 'login', delay: 0 }}
			>
				{!queryParams.setInitialPassword && (
					<div className="form-view__additional-fields">
						{/* <Link
							className="margin--bottom"
							href={getMainPath(routesDictionary.passwordReset)!}
							basePath="/"
						>
							{t('view.login.buttonLabel.forgotPassword')}
						</Link> */}
						{/* <SendPasswordLoginBodytext /> */}
					</div>
				)}
			</Form>
		</div>
	)
}

export default SendPasswordV2
